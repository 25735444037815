import React, { useState } from 'react';
import Product from './Product';
import useApi from 'react-use-api';
import queries from '../../services/queries';
import Carousel from 'react-multi-carousel';
import ProductLoader from '../Loaders/ProductLoader';
import { generateClassName } from '../../utils/generateClassName';
import { ITag } from '../../typings/ITag';
import { useSelector } from 'react-redux';
import { isBrowser } from '../../utils/isBrowser';
import { selectorsCart } from '../../redux/cart/cartReducer';
import { SelectorCity } from '../../redux/city/cityReducer';
import { selectorsOrder } from '../../redux/order/orderReducer';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';

interface ISliderProductsProps {
  config: any;
  title?: string;
  idCategory?: number;
  categories?: ITag[];
}

const SliderProducts = ({ config, idCategory, categories, title }: ISliderProductsProps) => {
  const idSelectedCity = useSelector(SelectorCity.getSelectedCityId)!;
  const cartSellPoint = useSelector(selectorsCart.getIdSellPoint)!;
  const isDeliverySelf = useSelector(selectorsOrder.isDeliverySelf);
  const [specCategory, setSpecCategory] = useState((categories ? categories[0].id : idCategory) as number);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const [data] = useApi(
    queries.getProducts({
      top: 10,
      skip: 0,
      idTag: specCategory,
      idSellPoint: isDeliverySelf ? cartSellPoint : undefined,
      idCity: isDeliverySelf ? undefined : idSelectedCity,
      isInCourierArea,
    }),
  );
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  title = title || buildLocale(currentTranslate, 'commonAlsoTasty');

  const handleSelectCategory = (id: number) => {
    if (categories) setSpecCategory(categories.find((spec) => spec.id === id)!.id);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {categories ? (
            <ul className="nav nav-tabs scrollbar-outer">
              {categories.map((spec) => {
                return (
                  <li key={spec.id} className="nav-item">
                    <button
                      className={generateClassName('nav-link', {
                        active: specCategory === spec.id,
                      })}
                      data-toggle="tab"
                      onClick={() => handleSelectCategory(spec.id)}>
                      {spec.name}
                    </button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <h3>{title}</h3>
          )}
          {
            <Carousel
              ssr={!isBrowser}
              responsive={config}
              itemClass="carousel-item-li"
              dotListClass="owl-dots"
              containerClass="carousel-con"
              customButtonGroup={<ButtonGroup />}
              renderButtonGroupOutside
              arrows={false}
              shouldResetAutoplay={false}
              autoPlay={false}
              customDot={<CustomDot />}
              renderDotsOutside
              showDots={true}
              focusOnSelect={false}
              draggable={true}
              swipeable={true}
              deviceType="desktop">
              {!data
                ? Array(4)
                    .fill(1)
                    .map((_, index) => {
                      return (
                        <div key={index} className="item">
                          <ProductLoader key={index} />
                        </div>
                      );
                    })
                : data.items.map((product) => {
                    return <ProductItem product={product} key={product.id} />;
                  })}
            </Carousel>
          }
        </div>
      </div>
    </div>
  );
};

const ProductItem = ({ product }: any) => {
  return (
    <div className="item">
      <Product product={product} />
    </div>
  );
};
export const CustomDot = ({ onClick, ...rest }: any) => {
  const {
    active,
    carouselState: { deviceType, totalItems },
  } = rest;

  if (deviceType === 'desktop' && totalItems <= 4) return null;
  return <button className={active ? 'owl-dot active' : 'owl-dot'} onClick={onClick} />;
};
export const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide, deviceType, slidesToShow, totalItems },
  } = rest;
  if (['tablet', 'mobile', 'minimobile'].some((s) => s === deviceType)) {
    return null;
  }
  return (
    <div className="owl-nav">
      <button
        aria-label="wurde zum vorherigen"
        className={currentSlide === 0 ? 'owl-prev disabled' : 'owl-prev'}
        onClick={previous}
      />
      <button
        aria-label="wurde zum nächsten"
        className={currentSlide + slidesToShow >= totalItems ? 'owl-next disabled' : 'owl-next'}
        onClick={next}
      />
    </div>
  );
};

export default SliderProducts;
